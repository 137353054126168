// component for display zone details
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import ZoneFullSection from "./ZoneFullSection";
import ZoneUpperSection from "./ZoneUpperSection";
import ZoneLowerSection from "./ZoneLowerSection";
import ObstructModal from "./ObstructModal";
import RotationModel from "./RotationModel";
import { SPECS_1900 } from "../../../services/Constant";

const ZoneDetail = ({
  isGreaterThan,
  isOntario,
  zone,
  deleteLightsFromZone,
  setKeysData,
  zoneLightsData,
  isMirrorToZoneD,
  isMirrorToZoneB,
  standardName,
  configName,
}) => {
  const [isObsModelOpen, setIsObsModelOpen] = useState(false);
  const [isRtnModelOpen, setIsRtnModelOpen] = useState(false);

  const [zoneName, setZoneName] = useState(false);
  const [arrIndex, setArrIndex] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [isFullSection, setIsFullSection] = useState(false);
  useEffect(async () => {
    setIsFullSection(
      standardName === "Ontario_5.0" ||
        standardName === "KKK_A_1822G" ||
        standardName === "CAAS_GSV_V3.0" ||
        ((standardName === SPECS_1900.CH10 || standardName === SPECS_1900.CH31 || standardName === SPECS_1900.ULC515) &&
          !isGreaterThan)
    );
  }, [standardName, isMirrorToZoneD, isMirrorToZoneB, configName]);
  const openAbstructModal = () => {
    setIsObsModelOpen(true);
  };

  const closeAbstructModal = (data) => {
    setIsObsModelOpen(false);
    if (data) {
      const obs = [];
      if (data.zoneA) {
        obs.push("A");
      }
      if (data.zoneB) {
        obs.push("B");
      }
      if (data.zoneC) {
        obs.push("C");
      }
      if (data.zoneD) {
        obs.push("D");
      }
      setKeysData(obs, "obstruct", arrIndex, zoneName);
    }
  };

  const setObstruct = (value, index, zoneData) => {
    setArrIndex(index);
    setZoneName(zoneData);
    setRowData(value);
    openAbstructModal();
  };

  const openRotationModal = () => {
    setIsRtnModelOpen(true);
  };

  const closeRotationModal = (data) => {
    setIsRtnModelOpen(false);
    if (data !== false) {
      setKeysData(data, "rotation", arrIndex, zoneName);
    }
  };

  const setRotation = (value, index, zoneData) => {
    setArrIndex(index);
    setZoneName(zoneData);
    setRowData(value);
    openRotationModal();
  };

  const onChangeQuantity = (e, index, mainData) => {
    setKeysData(e, "quantity", index, mainData);
  };

  const onChangeCallIntensity = (e, index, mainData, color) => {
    setKeysData(e, color ? `call_intensity${color}` : "call_intensity", index, mainData);
  };

  const onChangeBlockIntensity = (e, index, mainData, color) => {
    setKeysData(e, color ? `block_intensity${color}` : "block_intensity", index, mainData);
  };

  return (
    <>
      <Modal
        className="abstruct-modal"
        backdrop="static"
        tabIndex="-1"
        show={isObsModelOpen}
        onHide={closeAbstructModal}
      >
        <ObstructModal closeAbstructModal={closeAbstructModal} rowData={rowData} />
      </Modal>
      <Modal
        className="rotation-modal"
        backdrop="static"
        tabIndex="-1"
        show={isRtnModelOpen}
        onHide={closeRotationModal}
      >
        <RotationModel closeRotationModal={closeRotationModal} rowData={rowData} />
      </Modal>
      <div
        className={`${
          ((zone === "B" && isMirrorToZoneB) || (zone === "D" && isMirrorToZoneD)) && "disabled-div"
        } zone-details flex-column d-flex`}
        style={{
          height:
            isGreaterThan ||
            standardName === SPECS_1900.CH10 ||
            standardName === SPECS_1900.CH31 ||
            standardName === SPECS_1900.ULC515
              ? "600px"
              : "300px",
        }}
      >
        {(isGreaterThan || isFullSection) && (
          <>
            <ZoneUpperSection
              zone={zone}
              zoneLightsData={zoneLightsData}
              deleteLightsFromZone={deleteLightsFromZone}
              setObstruct={setObstruct}
              onChangeQuantity={onChangeQuantity}
              onChangeBlockIntensity={onChangeBlockIntensity}
              onChangeCallIntensity={onChangeCallIntensity}
              setRotation={setRotation}
              isOntario={isOntario}
              isMirrorToZoneB={isMirrorToZoneB}
              isMirrorToZoneD={isMirrorToZoneD}
            />
            <ZoneLowerSection
              zone={zone}
              zoneLightsData={zoneLightsData}
              deleteLightsFromZone={deleteLightsFromZone}
              setObstruct={setObstruct}
              onChangeQuantity={onChangeQuantity}
              onChangeBlockIntensity={onChangeBlockIntensity}
              onChangeCallIntensity={onChangeCallIntensity}
              setRotation={setRotation}
              isOntario={isOntario}
              isMirrorToZoneB={isMirrorToZoneB}
              isMirrorToZoneD={isMirrorToZoneD}
            />
          </>
        )}
        {!isFullSection && !isGreaterThan && (
          <ZoneFullSection
            zone={zone}
            zoneLightsData={zoneLightsData}
            deleteLightsFromZone={deleteLightsFromZone}
            setObstruct={setObstruct}
            onChangeQuantity={onChangeQuantity}
            onChangeBlockIntensity={onChangeBlockIntensity}
            onChangeCallIntensity={onChangeCallIntensity}
            setRotation={setRotation}
            isOntario={isOntario}
            isMirrorToZoneB={isMirrorToZoneB}
            isMirrorToZoneD={isMirrorToZoneD}
            isFullSection={isFullSection}
          />
        )}
      </div>
    </>
  );
};

export default ZoneDetail;
