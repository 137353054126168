// component for display zone Result page
import React, { useState, useEffect } from "react";
import { jsPDF as JsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import Modal from "react-bootstrap/Modal";
import { Subscription } from "rxjs";
import WhelenWImg from "../../../assets/images/Whelen-Bowtie-White.svg";
import helper from "../../../helper/helper";
import authService from "../../../services/AuthService";
import BrandIcon from "../../../assets/images/logo.png";
import PdfMoz from "./pdfMoz";
import ConfirmationModal from "../../Common/ConfirmationModal";
import ConfigService from "../../../services/ConfigService";
import { ONTARIO, PRIMARY_EMERGENCY } from "../../../services/Constant";

let standard = "";
const ZoneResultPageMoz = ({ closePerformCalculationResult, performCalculationData }) => {
  standard = performCalculationData.standard;
  let resultsData = [];
  const ZoneAData = [];
  const ZoneBData = [];
  const ZoneCData = [];
  const ZoneDData = [];

  const ZoneADataUpper = [];
  const ZoneBDataUpper = [];
  const ZoneCDataUpper = [];
  const ZoneDDataUpper = [];

  const ZoneADataLower = [];
  const ZoneBDataLower = [];
  const ZoneCDataLower = [];
  const ZoneDDataLower = [];
  const [processing, setProcessing] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const finalResultsData = new Map();
  const configDetail = {};
  let lightBars = [];
  const [isFail, setIsFail] = useState(false);
  const [lightbarHeight, setLightbarHeight] = useState(0);
  const [lengthObj, setLengthObj] = useState({});
  const [lightPagebreakObj, setLightPagebreakObj] = useState([]);
  const [warningDataPdf, setWarningForPDf] = useState([]);
  const [totalPageInWarningData, setTotalPageInWarningData] = useState(0);
  const [printWarning, setPrintWarning] = useState(false);
  const [remainingSpaceForLight, setRemainingSpaceForLight] = useState(0);
  const [lightHeightOnFirstPage, setLightHeightOnFirstPage] = useState(0);
  const [ops, setOS] = useState(null);

  let subscription = new Subscription();

  const { platform } = window.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "IOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  const calculateLength = () => {
    const obj = {};
    performCalculationData.lightBars.forEach((lightBar, index) => {
      let maxlength = 0;
      let frontLines = 0;
      let rareLines = 0;
      let cornerLines = 0;
      let alleyLines = 0;
      Object.keys(lightBar?.fronDirectional).map((key) => {
        frontLines += parseInt(key.length / 25, 10) + 1;
        return 0;
      });
      Object.keys(lightBar?.rearDirectional).map((key) => {
        rareLines += parseInt(key.length / 25, 10) + 1;
        return 0;
      });
      Object.keys(lightBar?.corners).map((key) => {
        cornerLines += parseInt(key.length / 25, 10) + 1;
        return 0;
      });
      Object.keys(lightBar?.alleys).map((key) => {
        alleyLines += parseInt(key.length / 25, 10) + 1;
        return 0;
      });
      maxlength = Math.max(frontLines, rareLines, cornerLines, alleyLines);
      obj[index] = maxlength * 12 + 40;
    });
    setLengthObj(obj);
  };

  const calPartialSpace = () => {
    let space = 630 - lightbarHeight; // remainingSpaceForLight;
    if (lightPagebreakObj && Object.keys(lightPagebreakObj)?.length) {
      space = remainingSpaceForLight;
    }
    return space;
  };

  const getLightBarIndexInfo = () => {
    const lightbarPageBreakDetails = {};
    let usefulPartialSpace = calPartialSpace() - 55;

    let space = 630;
    const partialPage = [];
    const completePages = {};
    let fillPartial = true;
    Object.keys(lengthObj).map((key) => {
      if (usefulPartialSpace >= lengthObj[key] && fillPartial) {
        usefulPartialSpace -= lengthObj[key];
        partialPage.push(performCalculationData.lightBars[key]);
      } else {
        if (!partialPage.length && fillPartial) {
          space -= 40;
        }
        fillPartial = false;
        if (space >= lengthObj[key]) {
          space -= lengthObj[key];
          const lastKey = Object.keys(completePages)?.length ? Object.keys(completePages).pop() : 0;
          if (lastKey === 0) {
            completePages[lastKey] = [];
          }
          completePages[lastKey].push(performCalculationData.lightBars[key]);
        } else {
          space = 630;
          space -= lengthObj[key];
          completePages[Object.keys(completePages)?.length] = [];
          const lastKey = Object.keys(completePages)?.length ? Object.keys(completePages).pop() : 0;
          completePages[lastKey].push(performCalculationData.lightBars[key]);
        }
      }
      return 0;
    });
    lightbarPageBreakDetails.partialPage = partialPage;
    lightbarPageBreakDetails.completePages = completePages;
    lightbarPageBreakDetails.partialSpace = !printWarning && !Object.keys(lengthObj).length ? 0 : calPartialSpace(); // in case of no warning && lightbars set height to 0
    lightbarPageBreakDetails.remainingSpaceAfterComplete = space;
    lightbarPageBreakDetails.pagePartialSpace = usefulPartialSpace;
    lightbarPageBreakDetails.lightHeightOnFirstPage = lightHeightOnFirstPage;

    return lightbarPageBreakDetails;
  };
  const getLightIndexInfo = (lightHeight) => {
    const indexList = {};
    let space = lightHeight;
    let flag = true;
    setLightHeightOnFirstPage(lightHeight);
    Object.keys(performCalculationData.lightsData).map((zone) =>
      performCalculationData.lightsData[zone].map((light, index) => {
        let lightLines = parseInt(`${light?.lightSource}-${light?.color}`.length / 15, 10) + 1;
        if (light?.excluded) {
          lightLines -= 1;
        }
        if ("importedBxrFileName" in light && light.importedBxrFileName !== null) {
          lightLines += 1; // FileName Height
        }
        if (light?.lightSource.indexOf("+") !== -1) {
          lightLines = 2;
          if (space < 10) {
            // exceptional case if multi-line light entry is near footer area
            space += 12.3;
          }
        }
        const lightNameHeight = lightLines > 1 ? lightLines * 12.3 : 12.3;
        if (space >= lightNameHeight) {
          space -= lightNameHeight;
          flag = true;
        } else if (flag) {
          indexList[zone + index] = `${parseInt(space, 10) + 100}px`;
          flag = false;
          space = 500;
        }
        return 0;
      })
    );
    setRemainingSpaceForLight(space);

    setLightPagebreakObj(indexList);
  };

  const getWarningDataPageBreakDetails = () => {
    setTotalPageInWarningData(1);
    const warningList = [];
    let space = 420;
    performCalculationData.warningData.map((light) => {
      const lightRow = `${light.lightSource} ${light.displayName} in ${light.zone} - ${light.zoneDetail}`;
      const lightLines = parseInt(lightRow.length / 58, 10); // calculating height of line
      let rowHeight = 12.5;

      if (lightLines > 1) {
        rowHeight = 12.5 * lightLines;
      }
      if (space >= rowHeight + 60 + 4) {
        warningList.push({ data: lightRow, rowMargin: 4 });
        space -= rowHeight;
      } else {
        warningList.push({ data: lightRow, rowMargin: space + 60 });
        space = 500;
        setTotalPageInWarningData((prev) => prev + 1);
      }
      return 0;
    });
    setWarningForPDf(warningList, () => {});
  };
  useEffect(async () => {
    if (performCalculationData.lightBars.length) {
      calculateLength();
    }
    ConfigService.getWarningMessageStatus().subscribe((value) => setPrintWarning(value));
    setOS(os);
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      subscription = new Subscription();
    };
  }, []);

  if (performCalculationData && performCalculationData !== null) {
    const blockingData = JSON.parse(JSON.stringify(performCalculationData.blockingDataResult));
    const callingData = JSON.parse(JSON.stringify(performCalculationData.callingDataResult));
    lightBars = performCalculationData.lightBars ? performCalculationData.lightBars : [];
    resultsData = [...blockingData, ...callingData];
    configDetail.standard = helper.getStdLabel(performCalculationData.standard);
    configDetail.footerText = helper.getfooterText(performCalculationData.standard);
    configDetail.name = performCalculationData.name;
    configDetail.sytemType = helper.getSysTypeLabel(performCalculationData.sytemType);
    configDetail.vehicle = performCalculationData.vehicle;
    configDetail.vin = performCalculationData.vin;
    configDetail.size = performCalculationData.size;
    configDetail.department = performCalculationData.department;
    configDetail.job = performCalculationData.job;
    configDetail.contract = performCalculationData.contract;
    configDetail.user = authService.getName();
    configDetail.date = helper.getDateForPDF(Date.now());
    const currentDrawData = JSON.parse(JSON.stringify(performCalculationData.lightsData));
    configDetail.currentMax = helper.getAllCurrentMax(currentDrawData);
    configDetail.currentAverage = helper.getAllCurrentAverage(currentDrawData);

    resultsData.map((results) => {
      const obj = results;
      delete obj.data;
      obj.level = results.level && results.level !== "single" ? results.level : "";
      finalResultsData.set(`zone${results.zone}${obj.type}${obj.level}`.trim().toLowerCase(), obj);
      if (!isFail && results.result === "Fail") {
        setIsFail(true);
      }
      if (results.zone === "A") {
        if (!results.level) {
          ZoneAData.push(obj);
        } else if (results.level === "upper") {
          ZoneADataUpper.push(obj);
        } else if (results.level === "lower") {
          ZoneADataLower.push(obj);
        }
      } else if (results.zone === "B") {
        if (!results.level) {
          ZoneBData.push(obj);
        } else if (results.level === "upper") {
          ZoneBDataUpper.push(obj);
        } else if (results.level === "lower") {
          ZoneBDataLower.push(obj);
        }
      } else if (results.zone === "C") {
        if (!results.level) {
          ZoneCData.push(obj);
        } else if (results.level === "upper") {
          ZoneCDataUpper.push(obj);
        } else if (results.level === "lower") {
          ZoneCDataLower.push(obj);
        }
      } else if (results.zone === "D") {
        if (!results.level) {
          ZoneDData.push(obj);
        } else if (results.level === "upper") {
          ZoneDDataUpper.push(obj);
        } else if (results.level === "lower") {
          ZoneDDataLower.push(obj);
        }
      }
      return null;
    });
  }

  const generatePDF = async (finaldata, imageUrl) => {
    setProcessing(true);
    const string = renderToString(
      <PdfMoz
        configDetail={configDetail}
        finalResultsData={finaldata}
        lightsData={performCalculationData.lightsData}
        excludedLightsInZone={performCalculationData.excludedLightsInZones}
        excludedLightsDuoTrioInZone={performCalculationData.excludedLightsDuoTrioInZones}
        lightbarPageBreakDetails={getLightBarIndexInfo()}
        lightPageBreakDetails={lightPagebreakObj}
        lengthLightBar={lengthObj}
        totalPageInWarningData={totalPageInWarningData}
        warningDataPdf={warningDataPdf}
        printWarning={printWarning}
        opSystem={ops}
      />
    );
    const pdf = new JsPDF("p", "px", "a4", true);
    const pdfName = `${configDetail.name}_${configDetail.standard}_${helper.formatDate(Date.now())}`
      .replaceAll(" ", "_")
      .replaceAll("/", "_");

    pdf.setProperties({
      title: pdfName,
    });
    // setPdfInstance(pdf); // setting PDF instance for later use;
    const imageData = new Image();
    imageData.src = BrandIcon;
    imageData.onload = function () {
      pdf.addImage(imageData, "JPEG", 185, 40, 80, 20);
      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "bold");
      pdf.text("Calculation Summary", 187, 65, { charSpace: 0.5 });
    };
    if (imageUrl) {
      const blob = await fetch(imageUrl).then((r) => r.blob());
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        const max = { height: 50, width: 50 }; // max limit after resize
        const maxUploadImageSize = { width: 500, height: 500 }; // max image upload size
        const imageObject = new Image();
        imageObject.src = base64data;
        imageObject.onload = function () {
          const imageHeight = imageObject.height;
          const imageWidth = imageObject.width;
          const ratioW = imageWidth / maxUploadImageSize.width;
          const ratioH = imageHeight / maxUploadImageSize.height;
          const imgWidthMaxNew = max.width * ratioW;
          const imgHeightMaxNew = max.height * ratioH;
          pdf.addImage(base64data, "JPEG", 350, 20, imgWidthMaxNew, imgHeightMaxNew);
          pdf.html(string, {
            callback(doc) {
              if (printWarning) {
                const totalPage = doc.internal.getNumberOfPages();
                doc.setPage(totalPageInWarningData > 1 ? totalPage - totalPageInWarningData + 1 : totalPage);
                doc.addImage(imageData, "JPEG", 185, 40, 80, 20);
                doc.setFontSize(9);
                doc.setFont("Helvetica", "bold");
                doc.text("Calculation Summary", 187, 65, { charSpace: 0.5 });
              }
              doc.autoPrint();
              window.open(pdf.output("bloburl"));
              setProcessing(false);
            },
          });
        };
      };
    } else {
      pdf.html(string, {
        callback(doc) {
          if (printWarning) {
            const totalPage = doc.internal.getNumberOfPages();
            doc.setPage(totalPageInWarningData > 1 ? totalPage - totalPageInWarningData + 1 : totalPage);
            doc.addImage(imageData, "JPEG", 185, 40, 80, 20);
            doc.setFontSize(9);
            doc.setFont("Helvetica", "bold");
            doc.text("Calculation Summary", 187, 65, { charSpace: 0.5 });
          }
          doc.autoPrint();
          window.open(pdf.output("bloburl"));
          setProcessing(false);
        },
      });
    }
  };

  const getCompanyLogo = (finalData, configDetails) => {
    setProcessing(true);
    ConfigService.getConfigById(configDetails)
      .then((response) => {
        if (response && response.data) {
          generatePDF(finalData, response?.data?.companyLogo);
        }
      })
      .catch((error) => {
        setProcessing(false);
        helper.displayErrorMessage(error);
      });
  };

  const openConfirmationModal = () => {
    setShowCheck(true);
  };

  const checkForPrintDocPopup = () => {
    const configDetails = ConfigService.getConfigDetailsFromStorage();
    if (isFail) {
      openConfirmationModal();
    } else if (configDetails?.id) {
      getCompanyLogo(finalResultsData, configDetails);
    } else {
      generatePDF(finalResultsData);
    }
  };

  const closeConfirmationModal = (data) => {
    const configDetails = ConfigService.getConfigDetailsFromStorage();
    setShowCheck(false);
    if (data) {
      const filteredData = new Map();
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of finalResultsData.entries()) {
        if (value.result === "Pass") {
          filteredData.set(key, value);
        }
      }
      if (configDetails?.id) {
        getCompanyLogo(filteredData, configDetails);
      } else {
        generatePDF(filteredData);
      }
    } else if (configDetails?.id) {
      getCompanyLogo(finalResultsData, configDetails);
    } else {
      generatePDF(finalResultsData);
    }
  };
  const setLightBarHeight = (height) => {
    setLightbarHeight(height);
  };
  const setLightsHeight = (height) => {
    getLightIndexInfo(height);
  };
  const setWarningHeight = (height) => {
    getWarningDataPageBreakDetails(height);
  };
  const returnPdf = () => (
    <PdfMoz
      finalResultsData={finalResultsData}
      configDetail={configDetail}
      lightsData={performCalculationData.lightsData}
      excludedLightsInZone={performCalculationData.excludedLightsInZone}
      excludedLightsDuoTrioInZone={performCalculationData.excludedLightsDuoTrioInZone}
      lightBars={lightBars}
      setLightBarHeight={setLightBarHeight}
      setLightsHeight={setLightsHeight}
      lengthLightBar={lengthObj}
      setWarningHeight={setWarningHeight}
      totalPageInWarningData={totalPageInWarningData}
      warningDataPdf={warningDataPdf}
      printWarning={printWarning}
      opSystem={ops}
    />
  );
  return (
    <div>
      <Modal className="saveasModal" backdrop="static" tabIndex="-1" show={showCheck} onHide={closeConfirmationModal}>
        <ConfirmationModal closeConfirmationModal={closeConfirmationModal} message="fromResultPage" />
      </Modal>
      <div className="result-content">
        <div className="sidebar-header" />
        <h1 className="text-white">Result</h1>
        <div className="result-listing">
          <div className="zone-result style">
            <div className="zonewise-listing">
              {ZoneAData.length ? <h3>{`Zone ${ZoneAData[0].zone}`}</h3> : null}
              {ZoneAData && ZoneAData.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneADataUpper.length ? (
                <h3>{`Zone ${ZoneADataUpper[0].zone} ${ZoneADataUpper[0].level && ZoneADataUpper[0].level}`}</h3>
              ) : null}
              {ZoneADataUpper && ZoneADataUpper.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneADataLower.length ? (
                <h3>{`Zone ${ZoneADataLower[0].zone} ${ZoneADataLower[0].level && ZoneADataLower[0].level}`}</h3>
              ) : null}
              {ZoneADataLower && ZoneADataLower.map((results) => <RendersData record={results} />)}
            </div>

            <div className="zonewise-listing">
              {ZoneBData.length ? <h3>{`Zone ${ZoneBData[0].zone}`}</h3> : null}
              {ZoneBData && ZoneBData.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneBDataUpper.length ? (
                <h3>{`Zone ${ZoneBDataUpper[0].zone} ${ZoneBDataUpper[0].level && ZoneBDataUpper[0].level}`}</h3>
              ) : null}
              {ZoneBDataUpper && ZoneBDataUpper.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneBDataLower.length ? (
                <h3>{`Zone ${ZoneBDataLower[0].zone} ${ZoneBDataLower[0].level && ZoneBDataLower[0].level}`}</h3>
              ) : null}
              {ZoneBDataLower && ZoneBDataLower.map((results) => <RendersData record={results} />)}
            </div>

            <div className="zonewise-listing">
              {ZoneCData.length ? <h3>{`Zone ${ZoneCData[0].zone}`}</h3> : null}
              {ZoneCData && ZoneCData.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneCDataUpper.length ? (
                <h3>{`Zone ${ZoneCDataUpper[0].zone} ${ZoneCDataUpper[0].level && ZoneCDataUpper[0].level}`}</h3>
              ) : null}
              {ZoneCDataUpper && ZoneCDataUpper.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneCDataLower.length ? (
                <h3>{`Zone ${ZoneCDataLower[0].zone} ${ZoneCDataLower[0].level && ZoneCDataLower[0].level}`}</h3>
              ) : null}
              {ZoneCDataLower && ZoneCDataLower.map((results) => <RendersData record={results} />)}
            </div>

            <div className="zonewise-listing">
              {ZoneDData.length ? <h3>{`Zone ${ZoneDData[0].zone}`}</h3> : null}
              {ZoneDData && ZoneDData.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneDDataUpper.length ? (
                <h3>{`Zone ${ZoneDDataUpper[0].zone} ${ZoneDDataUpper[0].level && ZoneDDataUpper[0].level}`}</h3>
              ) : null}
              {ZoneDDataUpper && ZoneDDataUpper.map((results) => <RendersData record={results} />)}
            </div>
            <div className="zonewise-listing">
              {ZoneDDataLower.length ? (
                <h3>{`Zone ${ZoneDDataLower[0].zone} ${ZoneDDataLower[0].level && ZoneDDataLower[0].level}`}</h3>
              ) : null}
              {ZoneDDataLower && ZoneDDataLower.map((results) => <RendersData record={results} />)}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button type="submit" className="btn btn-red" onClick={closePerformCalculationResult}>
              Modify Configuration
            </button>
            <button type="button" className="btn btn-red" onClick={checkForPrintDocPopup}>
              {processing ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
              Print Document
            </button>
          </div>
        </div>
        <div className="text-center" style={{ position: "absolute", left: "0", right: "0", bottom: "3%" }}>
          <img alt="" src={WhelenWImg} className="img-fluid" style={{ height: "33px" }} />
        </div>
      </div>
      <div className="result-pdf">{returnPdf()}</div>
    </div>
  );
};

export default ZoneResultPageMoz;

const RendersData = ({ record }) => (
  <>
    <div className="d-flex justify-content-between">
      <p>{standard === ONTARIO.VALUE ? PRIMARY_EMERGENCY : `${record.type} for right of way`}</p>
      <p className={record.result === "Pass" ? "color-green" : "color-red"}>{record.result}</p>
    </div>
  </>
);
