import React from "react";
import { Helmet } from "react-helmet";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainController from "./components/MainController";

function App() {
  injectStyle();
  return (
    <>
      <Helmet titleTemplate="%s - Whelen - AOG User Console" defaultTitle="Whelen - AOG User Console">
        <meta name="description" content="AOG User Console" />
      </Helmet>
      <MainController />
      <ToastContainer />
      <img
        src="https://d21y75miwcfqoq.cloudfront.net/782b9649"
        style={{ position: "position" }}
        alt=""
        referrerpolicy="strict-origin-when-cross-origin"
      />
    </>
  );
}

export default App;
